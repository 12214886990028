a {
  text-decoration: none;
  color: black;
}

.sidebar {
  background: #2b6777;
  height: Calc(100vh - 50px);
  overflow-y: scroll !important;
}

.sidebarContainer {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
}

.sidebarRow {
  display: flex;
  margin: auto 0;
  justify-content: baseline;
  padding-bottom: 10px;
  align-items: center;
}

.sidebarChannelIcon {
  padding: 5px;
  width: 50px;
  background: white;
  vertical-align: middle;
  text-align: center;
}

.sidebarChannelIcon > img {
  max-width: 50px;
  max-height: 30px;
  margin: 0 auto;
}

.channelID {
  font-weight: 500;
  padding-right: 3px;
}

.channelName {
  padding-left: 10px;
  font-weight: 300;
  font-size: small;
  color: white;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .channelName {
    display: none;
  }
}
