.play4default {
  max-width: 100%;
  height: Calc(100% - 50px);
  background-color: black;
}

.play4Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: auto;
  height: 100vh;
}

@media all and (orientation: portrait) {
  .play4Container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
