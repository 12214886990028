.header {
  background: #f2f2f2;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.headerContainer {
  margin: auto;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 1;
}

.headerTitle {
  padding-left: 20px;
  font-size: large;
  font-weight: 500;
  color: black;
  white-space: nowrap;
  vertical-align: middle;
}

.headerLinksBar {
  padding-left: 30px;
  display: flex;
  flex: 1;
  align-items: center;
}

.headerLink {
  margin-right: 10px;
  white-space: nowrap;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .header {
    background: #f2f2f2;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .headerContainer {
    margin: auto;
    align-items: center;
    display: block;
  }

  .headerTitle {
    padding-left: 10px;
    font-weight: 500;
    color: black;
    white-space: nowrap;
    vertical-align: middle;
  }

  .headerLinksBar {
    padding-top: 5px;
    display: flex;
    flex: 1;
  }

  .headerLink {
    margin-right: 5px;
    white-space: nowrap;
  }

  .reactive-btn-wrapper,
  .reactive-btn {
    font-size: x-small;
  }
}
