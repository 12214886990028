.watchtv {
  background: #c8d8e4;
  height: 100% !important;
  width: 100%;
}

.watchTV_Video {
  padding-top: 10px;
}

.watchTV_NowplayingChannel {
  display: flex;
  flex: 1;
}

.watchTV_NowplayingChannel > img {
  margin-left: 15px;
  max-height: 30px;
  justify-content: center;
}

.watchTV_NowPlayingTitle {
  background: #52ab98;
  padding: 5px;
  width: 90px;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
}

.watchTV_NowPlayingChannel {
  background: gray;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: white;
}
