.japanytlive {
  max-width: 100%;
  height: Calc(100vh - 50px);
  background-color: black;
}

.japanytlive-rootcontainer {
  position: relative;
  height: 100%;
  background-color: black;
}

.japanytlive-settings {
  width: 250px;
  min-height: 100%;
  background: #2b6777;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.fab-settings {
  position: absolute !important;
  z-index: 10;
  top: 10px;
  right: 10px;
}

.japanytlive-settingHeader {
  padding-bottom: 10px;
  font-weight: 500;
  font-size: large;
  white-space: nowrap;
}

.japanytlive-settingRow {
  display: flex;
  flex: 1;
  vertical-align: center;
  padding-bottom: 10px;
}

.japanytlive-settingRow > span {
  white-space: nowrap;
}

.settings-gridBox {
  color: white;
}

.japanytliveContainer,
.japanytliveContainer1 {
  display: grid;
  grid-template-columns: 1fr;
}

.japanytliveContainer4,
.japanytliveContainer6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.japanytliveContainer9 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.youtube-videoContainer {
  position: relative;
  padding-top: 56.25%;
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media all and (orientation: portrait) and (min-width: 0px) and (max-width: 500px) {
  .japanytliveContainer,
  .japanytliveContainer1,
  .japanytliveContainer4,
  .japanytliveContainer9 {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media all and (orientation: portrait) and (min-width: 501px) and (max-width: 900px) {
  .japanytliveContainer,
  .japanytliveContainer1 {
    display: grid;
    grid-template-columns: 1fr;
  }

  .japanytliveContainer4,
  .japanytliveContainer6,
  .japanytliveContainer9 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
