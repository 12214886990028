html, body {
  height: 100%;
  background-color: black;
}

.appRow {
  display: flex;
  flex: 1;
  width: 100%;
  height: Calc(100% - 80px) !important;
}

.leftSidebar {
  flex: 20;
}

.rightContent {
  flex: 80;
}

.fullContent {
  flex: 100;
}
